import React, { useState, useContext, useRef, useEffect } from 'react';
import {
  Box,
  Typography,
  Chip,
  Button,
  Container,
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar
} from '@material-ui/core';
import { colors } from '@loggi/mar';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import HeaderWithReturn from '../../app/components/header-with-return';
import { BarcodeReader } from '../../app/components/barcode-readers';
import showSnackbar from '../../app/components/snackbar/snackbar-container';
import { ReactComponent as LocalShippingIcon } from '../../assets/images/local_shipping.svg';
import { ReactComponent as ShelfIcon } from '../../assets/images/shelf.svg';
import {
  playErrorBeep,
  playSuccessBeep,
  playActionRequiredBeep
} from '../../sounds';
import { useDistributionCenter } from '../../app/access-control/distribution-center-provider';
import { custodyExchange, custodyExchangeVerifyUnitLoad } from '../../api-rest';
import {
  CUSTODY_EXCHANGE_TYPE,
  ROUTES,
  DISTRIBUTE_TO,
  ACTIVITY,
  OPERATIONAL_PROCESS,
  CUSTODY_EXCHANGE_CONTEXTS,
  SWITCHES
} from '../../constants';
import { DistributeProcessContext } from './distribute-process-context';
import { ActivityTrackingContext } from '../../app/activity-tracking/activity-tracking-provider';
import { useFeature } from '../../app/hooks/use-feature';

function BagPackageReader({
  goBack,
  vehicleLicensePlate,
  contextLicensePlate
}) {
  const enableOperationVerificationOnPackageReader = useFeature(
    SWITCHES.enableOperationVerificationOnPackageReader
  );
  const enablePartnerDistributeContextReader = useFeature(
    SWITCHES.enablePartnerDistributeContextReader
  );

  const { setDistributeType, setTotalCargosBeeped } = useContext(
    DistributeProcessContext
  );
  const { trackStart, trackEnd } = useContext(ActivityTrackingContext);
  const history = useHistory();
  const [barcodes, setBarcodes] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { selectedDistributionCenter }
  } = useDistributionCenter();
  const listRef = useRef(null);

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTop = listRef.current.scrollHeight;
    }
  }, [barcodes]);

  const showSnackbarAlert = (message, variant = 'success') => {
    showSnackbar({
      variant,
      message,
      showCloseButton: true,
      enqueueSnackbar
    });
  };

  let custodyExchangeType = '';

  switch (true) {
    case contextLicensePlate?.includes(CUSTODY_EXCHANGE_CONTEXTS.REVERSE):
      custodyExchangeType = CUSTODY_EXCHANGE_TYPE.REVERSE;
      break;
    case contextLicensePlate?.includes(CUSTODY_EXCHANGE_CONTEXTS.DEVOLUTION):
      custodyExchangeType = CUSTODY_EXCHANGE_TYPE.DEVOLUTION;
      break;
    default:
      custodyExchangeType = CUSTODY_EXCHANGE_TYPE.REVERSE;
  }

  const verifyUnitLoad = async barcode => {
    try {
      const response = await custodyExchangeVerifyUnitLoad(
        barcode,
        custodyExchangeType,
        selectedDistributionCenter?.distributionCenterId
      );
      return response;
    } catch (err) {
      return null;
    }
  };

  const handleBarcodeRead = async barcode => {
    trackStart(
      OPERATIONAL_PROCESS.BEEP_LATENCY,
      ACTIVITY.DISTRIBUTE_PARTNER_BAG_PACKAGE_BEEP
    );
    let response = null;
    if (enableOperationVerificationOnPackageReader) {
      response = await verifyUnitLoad(barcode);
    }
    setBarcodes(prevBarcodes => {
      if (!prevBarcodes.includes(barcode)) {
        if (!enableOperationVerificationOnPackageReader) {
          playSuccessBeep();
          return [...prevBarcodes, barcode];
        }
        if (response != null && response.status === 200) {
          playSuccessBeep();
          return [...prevBarcodes, barcode];
        }
        playErrorBeep();
        showSnackbarAlert(
          `ERRO: O barcode ${barcode} não faz parte da operação!`,
          'error'
        );
        return prevBarcodes;
      }
      playActionRequiredBeep();
      showSnackbarAlert('Código de barras já lido!', 'warning');
      return prevBarcodes;
    });
    trackEnd(
      OPERATIONAL_PROCESS.BEEP_LATENCY,
      ACTIVITY.DISTRIBUTE_PARTNER_BAG_PACKAGE_BEEP
    );
  };

  const custodyExchangeCall = async () => {
    try {
      const response = await custodyExchange(
        vehicleLicensePlate,
        barcodes,
        custodyExchangeType,
        selectedDistributionCenter?.distributionCenterId
      );
      return response;
    } catch (err) {
      playErrorBeep();
      showSnackbarAlert(
        `ERRO: ${err.response?.data?.message || err.message}`,
        'error'
      );
      return null;
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();
    trackStart(
      OPERATIONAL_PROCESS.DISTRIBUTE,
      ACTIVITY.DISTRIBUTE_PARTNER_CUSTODY_EXCHANGE
    );

    const response = await custodyExchangeCall();

    trackEnd(
      OPERATIONAL_PROCESS.DISTRIBUTE,
      ACTIVITY.DISTRIBUTE_PARTNER_CUSTODY_EXCHANGE
    );
    if (response != null && response.status === 200) {
      setTotalCargosBeeped(barcodes.length);
      setDistributeType(DISTRIBUTE_TO.PARTNER);
      history.push({
        pathname: ROUTES.DISTRIBUTE.NEW_DISTRIBUTION
      });
      return;
    }
    history.push({
      pathname: ROUTES.HOME
    });
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      overflow="hidden"
      justifyContent="space-between"
      height="100vh"
      data-testid="distribute-bag-package-reader"
    >
      <HeaderWithReturn title="Distribuir" onReturn={goBack} />
      <Container maxWidth="xs">
        <Box
          pt={10}
          mb={3}
          display="flex"
          flexDirection="column"
          overflow="hidden"
        >
          <BarcodeReader
            dataTestId="barcode-input"
            onRead={handleBarcodeRead}
            placeholder="Leia o código de barras"
            notes={`Placa do veículo: ${vehicleLicensePlate}`}
          />
        </Box>
        <Chip
          style={{
            backgroundColor: colors.smoke[100],
            color: colors.root[900],
            marginBottom: '12px'
          }}
          icon={<LocalShippingIcon />}
          size="medium"
          variant="outlined"
          label={vehicleLicensePlate}
        />
        {enablePartnerDistributeContextReader && (
          <Chip
            style={{
              backgroundColor: colors.smoke[100],
              color: colors.root[900],
              marginLeft: '8px',
              marginBottom: '12px'
            }}
            icon={<ShelfIcon />}
            size="medium"
            variant="outlined"
            label={contextLicensePlate}
          />
        )}
        <Box display="flex" flexDirection="column" width="100%" mt={1}>
          <Box
            ref={listRef}
            display="flex"
            flexDirection="column"
            style={{
              overflowY: 'auto',
              maxHeight: `calc(70vh - 100px)`
            }}
            flex="1 1 1"
          >
            {Array.isArray(barcodes) &&
              barcodes.map((code, index) => (
                <Box
                  key={code}
                  mb={index === barcodes.length - 1 ? 0 : 1}
                  data-testid={`barcode-item-${code}`}
                >
                  <Divider />
                  <Box
                    mt={1}
                    mb={index === barcodes.length - 1 ? 1 : 0}
                    maxWidth="300px"
                  >
                    <ListItem style={{ paddingLeft: '0' }}>
                      <ListItemAvatar>
                        <Avatar
                          style={{
                            width: '40px',
                            height: '30px',
                            borderStyle: 'solid',
                            borderWidth: 'thin',
                            backgroundColor: 'transparent',
                            borderColor: colors.blue[500],
                            borderRadius: 10
                          }}
                        >
                          <Typography
                            display="block"
                            style={{ fontWeight: 600, color: colors.root[900] }}
                          >
                            {index + 1}
                          </Typography>
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Typography
                            display="block"
                            style={{ fontWeight: 'bold' }}
                          >
                            {code}
                          </Typography>
                        }
                      />
                    </ListItem>
                  </Box>
                  {index === barcodes.length - 1 && <Divider />}
                </Box>
              ))}
          </Box>
        </Box>
      </Container>
      <Container
        maxWidth="xs"
        style={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
      >
        <Box flex="none" mb={3}>
          <Button
            type="submit"
            fullWidth
            size="large"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={barcodes.length === 0}
          >
            Enviar Lista
          </Button>
        </Box>
      </Container>
    </Box>
  );
}

BagPackageReader.propTypes = {
  vehicleLicensePlate: PropTypes.string,
  contextLicensePlate: PropTypes.string,
  goBack: PropTypes.func
};

BagPackageReader.defaultProps = {
  vehicleLicensePlate: '',
  contextLicensePlate: 'DOCA REV',
  goBack: () => {}
};

export default BagPackageReader;
